// InternalDischargeDocumentData.js
import React, { useEffect } from 'react';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const InternalDischargeDocumentData = ({ documentDetails, setDocumentDetails }) => {
  // Recupera il prossimo numero di documento quando il componente viene montato
  useEffect(() => {
    const fetchNextDocumentNumber = async () => {
      try {
        const response = await fetch(`${serverUrl}/internal-discharges/next-document-number`);
        if (response.ok) {
          const data = await response.json();
          setDocumentDetails((prevDetails) => ({
            ...prevDetails,
            document_number: data.nextDocumentNumber,
            creation_date: prevDetails.creation_date || new Date().toISOString().split('T')[0],
          }));
        } else {
          console.error('Failed to fetch next document number.');
        }
      } catch (error) {
        console.error('Error fetching next document number:', error);
      }
    };

    fetchNextDocumentNumber();
  }, [setDocumentDetails]);

  // Gestisci il cambiamento degli input del documento
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div className="p-5 bg-white rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold mb-5">Dati Documento</h2>
      <form className="space-y-4">
        <div className="flex flex-col space-y-2">
          <label className="font-medium">Numero Documento</label>
          <input
            type="number"
            name="document_number"
            value={documentDetails.document_number || ''}
            onChange={handleInputChange}
            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label className="font-medium">Data Creazione</label>
          <input
            type="date"
            name="creation_date"
            value={documentDetails.creation_date || ''}
            onChange={handleInputChange}
            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label className="font-medium">Note</label>
          <textarea
            name="notes"
            value={documentDetails.notes || ''}
            onChange={handleInputChange}
            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
          />
        </div>
      </form>
    </div>
  );
};

export default InternalDischargeDocumentData;
