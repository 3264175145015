import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DocumentsTable from '../components/DocumentsTable';
import DocumentSidebar from '../components/DocumentSidebar';
import AddDocumentModal from '../components/AddDocumentModal';
import './DocumentsPage.css';

const DocumentsPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle the sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Toggle the modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

   // Handle exit from the page
   const handleExit = () => {
    if (location.state?.from) {
      navigate(location.state.from); // Torna alla pagina di provenienza
    } else {
      navigate('/Home'); // Vai alla home se non c'è stato di provenienza
    }
  };
  

  // Handle adding a new document
  const handleAddDocument = (documentType) => {
    switch (documentType) {
      case 'DDT':
        navigate('/ddt', { state: { from: '/documents' } }); // Pass the source page state
        break;
      case 'Invoice':
        navigate('/invoice', { state: { from: '/documents' } });
        break;
      case 'InternalDischarge':
        navigate('/internalDischarge', { state: { from: '/documents' } });
        break;
      default:
        break;
    }
  };

  return (
    <div className="documents-page">
      <h1>Documenti</h1>
      <button className="filter-button" onClick={toggleSidebar}>
        Filtri
      </button>
      <DocumentsTable />
      <div className="footer-container">
        <button className="footer-button" onClick={toggleModal}>
          Aggiungi Documento
        </button>

        <button className="footer-button" onClick={handleExit}>
          Esci
        </button>
      </div>
      {isSidebarOpen && (
        <div className="document-sidebar document-sidebar-open">
          <DocumentSidebar onClose={toggleSidebar} />
        </div>
      )}
      {isModalOpen && (
        <AddDocumentModal onClose={toggleModal} onSubmit={handleAddDocument} />
      )}
    </div>
  );
};

export default DocumentsPage;
