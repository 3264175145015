import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { generateDDTPDF } from '../utils/DDTPDF';
import DDTCustomerData from '../components/DDTCustomerData';
import DDTDocumentData from '../components/DDTDocumentData';
import DDTDataInput from '../components/DDTDataInput';
import setup from '../config/setup.json';

const DDTPage = () => {
  const location = useLocation();
  const [customerData, setCustomerData] = useState({});
  const [documentDetails, setDocumentDetails] = useState({});
  const [inputData, setInputData] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // Stato per il tipo di messaggio
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.selectedRepairs) {
      setInputData(location.state.selectedRepairs);
    }
  }, [location.state]);

  const handleSaveDDT = async () => {
    try {
      // Controllo dei campi obbligatori
      if (!documentDetails.document_number) {
        setMessage('Numero del documento obbligatorio.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.creation_date) {
        setMessage('Data di creazione obbligatoria.');
        setMessageType('error');
        return;
      }
      if (!customerData.id_customer) {
        setMessage('Selezionare un cliente.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.number_of_packages || !documentDetails.weight) {
        setMessage('Numero colli e peso sono obbligatori.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.transport_reason) {
        setMessage('La causale del trasporto è obbligatoria.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.transport_by) {
        setMessage('Il trasporto a cura del è obbligatorio.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.shipment_port) {
        setMessage('Il porto è obbligatorio.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.courier) {
        setMessage('Il corriere è obbligatorio.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.appearance_of_goods) {
        setMessage("L'aspetto dei beni esteriori è obbligatorio.");
        setMessageType('error');
        return;
      }
      if (!documentDetails.repair_status) {
        setMessage('Selezionare uno stato per le riparazioni.');
        setMessageType('error');
        return;
      }
  
      // Preparo i dati per il salvataggio
      const ddtData = {
        document_number: documentDetails.document_number,
        creation_date: documentDetails.creation_date,
        transport_reason: documentDetails.transport_reason,
        number_of_packages: documentDetails.number_of_packages,
        weight: documentDetails.weight,
        destination: documentDetails.destination || 'Default Destination',
        delivery_date: documentDetails.delivery_date || new Date().toISOString().split('T')[0],
        appearance_of_goods: documentDetails.appearance_of_goods,
        shipment_port: documentDetails.shipment_port,
        courier: documentDetails.courier,
        transport_by: documentDetails.transport_by,
        id_customer: customerData.id_customer,
        repair_status: documentDetails.repair_status,
        id_laboratory: documentDetails.id_laboratory,
        repairs: inputData
          .filter((item) => item.repairCode)
          .map((item) => ({
            id_repair: item.repairCode,
          })),
        manual_entries: inputData
          .filter((item) => item.description && !item.repairCode)
          .map((item) => ({
            description: item.description,
            quantity: item.quantity,
          })),
      };
  
      console.log('dati da inviare al server DDT', ddtData);
  
      const serverUrl = setup.serverUrl;
      const saveDDTResponse = await axios.post(`${serverUrl}/ddts`, ddtData);
      console.log('risposta dopo aver inviato i dati di DDT', saveDDTResponse);
  
      // Genera il PDF del DDT
      const pdfBlob = new Blob([generateDDTPDF(documentDetails, customerData, inputData)], {
        type: 'application/pdf',
      });
  
      // Crea il form data per inviare il PDF al server
      const formData = new FormData();
      formData.append('pdf', pdfBlob, `${documentDetails.document_number}.pdf`);
  
      // Salva il PDF sul server
      const fileUploadResponse = await axios.post(`${serverUrl}/ddts/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Aggiorna il DDT con il percorso del PDF
      await axios.put(`${serverUrl}/ddts/${documentDetails.document_number}`, {
        pdf_path: fileUploadResponse.data.pdfPath,
      });
  
      setMessage('DDT salvato con successo!');
      setMessageType('success');
      setDocumentDetails({ ...documentDetails, pdf_generated: true });
    } catch (error) {
      console.error('Errore durante il salvataggio del DDT:', error);
      setMessage('Errore durante il salvataggio del DDT.');
      setMessageType('error');
    }
  };
  

  const handleGeneratePDF = async () => {
    try {
      if (!documentDetails.pdf_generated) {
        setMessage('PDF non ancora generato. Salvare il DDT prima di generare il PDF.');
        setMessageType('error');
        return;
      }
      const serverUrl = setup.serverUrl;
      const response = await axios.get(`${serverUrl}/ddts/${documentDetails.document_number}/pdf`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(url, '_blank');
    } catch (error) {
      console.error('Errore durante il recupero del PDF:', error);
      setMessage('Errore durante il recupero del PDF.');
      setMessageType('error');
    }
  };

  // Handle exit from the page
  const handleExit = () => {
    if (location.state?.from) {
      navigate(location.state.from); // Torna alla pagina di provenienza
    } else {
      navigate('/Home'); // Vai alla home se non c'è stato di provenienza
    }
  };

  return (
    <div className="p-5 max-w-6xl mx-auto bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-5">Documento di Trasporto (DDT)</h1>

      {/* Container per Dati Cliente e Dati Fattura */}
      <div className="lg:flex lg:space-x-5 space-y-5 lg:space-y-0 mb-5">
        <div className="flex-1 bg-blue-50 p-5 rounded-lg shadow-md">
          <DDTCustomerData customerData={customerData} setCustomerData={setCustomerData} />
        </div>
        <div className="flex-1 bg-blue-50 p-5 rounded-lg shadow-md">
          <DDTDocumentData documentDetails={documentDetails} setDocumentDetails={setDocumentDetails} />
        </div>
      </div>

      {/* Invoice Input al di sotto dei due box */}
      <div className="mb-5">
        <DDTDataInput documentLines={inputData} setDocumentLines={setInputData} />
      </div>

      {/* Azioni della fattura */}
      <div className="flex justify-end gap-3 mt-5">
        <button onClick={handleSaveDDT} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300">
          Salva 
        </button>
        <button onClick={handleGeneratePDF} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition duration-300">
          Genera PDF
        </button>
        <button onClick={handleExit} className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-800 transition duration-300">
          Esci
        </button>
      </div>

      {/* Messaggio di conferma o errore */}
      {message && (
        <div
          className={`mt-5 p-3 rounded text-lg ${
            messageType === 'error'
              ? 'bg-red-200 text-red-800 border border-red-400'
              : 'bg-green-200 text-green-800 border border-green-400'
          }`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default DDTPage;
